import "./styles/home.scss"

import Glide from '@glidejs/glide'

document.addEventListener("DOMContentLoaded", function() {

    new Glide('.glide', {
        type: 'slider',
        hoverpause: false,
        autoplay: 3000,
        animationDuration: 1000,
        animationTimingFunc: 'ease-in',
        gap:0,
    }).mount();

});
